import React, { useEffect, useState } from "react";

import { useLazyQuery } from "@apollo/client";

import PromoBlock from "@/components/common/promoBlock";
import { isNotNullOrUndefined } from "@/utils/commonUtils";
import { cleanupFromDangerousTags } from "@/utils/stringUtils";
import Breadcrumbs from "@components/common/Breadcrumbs";
import { BreadcrumbUrlPrefixEnum } from "@components/common/Breadcrumbs/types";
import SeoHeaders from "@components/common/seoHeaders";

import BulletLinkBlock from "../bulletLinkBlock";
import { SHOWN_EXPERTS_COUNT } from "../constants";
import Constructor from "../constructor";
import ExpertsBlock from "../expertsBlock";
import LabelLinkBlock from "../labelLinkBlock";
import LinkBlock from "../linkBlock";
import { LinkUrlPrefixEnum } from "../types";
import { buildExpertList, getLinkBlockObject, shouldLinkBeBullet } from "../utils";

import {
  GetGidExperts,
  GetGidExperts_getExperts_edges,
} from "./graphql/__generated__/GetGidExperts";
import { GET_GID_EXPERTS } from "./graphql/GET_GID_EXPERTS";
import { GidProps } from "./types";

import "../styles.scss";
import "./styles.scss";

const Gid = ({ pageContext }: GidProps) => {
  const {
    seoId,
    title = "Гид на Lunaro",
    ogTitle,
    description = "Гид на Lunaro",
    textTop,
    textMiddle,
    textBottom,
    header,
    breadcrumbDisplayName,
    breadcrumbs,
    gidLinks = [],
    catalogLinks = [],
    categoryLinks = [],
    articleLinks = [],
    autolinkedGidLinks,
    url,
    expertsInitial,
    zone,
    commonTextTopBottomZone,
    commonTextMiddleBottomZone,
    commonBottomZone,
  } = pageContext;

  const [experts, setExperts] = useState<GetGidExperts_getExperts_edges[]>(
    buildExpertList(expertsInitial, SHOWN_EXPERTS_COUNT),
  );

  // Data are cached in Apollo cache since we don't need very up-to-date expert list in categories
  const [getGidExperts, {
    data,
    error,
    loading,
  }] = useLazyQuery<GetGidExperts>(GET_GID_EXPERTS);

  useEffect(() => {
    getGidExperts();
  }, [getGidExperts, url]);

  useEffect(() => {
    if (data && !error && !loading) {
      if (data.getExperts?.edges) {
        // todo: ask to make non-null experts on backend. Until then this variable will be red
        const nonNullExperts: GetGidExperts_getExperts_edges[] = data.getExperts.edges
          .filter(isNotNullOrUndefined);
        setExperts(buildExpertList(nonNullExperts, SHOWN_EXPERTS_COUNT));
      }
    }
  }, [data, error, loading]);

  return (
    <>
      <SeoHeaders
        title={title}
        ogTitle={ogTitle}
        description={description}
        url={url}
        imageUrl={`${textTop}${textMiddle}${textBottom}`?.match(/src\s*=\s*"(.+?)"/)?.[1]}
      />
      <PromoBlock />
      <div className="bg--white gid">
        <div className="page-width">
          <Breadcrumbs
            seoId={seoId}
            url={url}
            breadcrumbDisplayName={breadcrumbDisplayName || ""}
            breadcrumbs={breadcrumbs}
            urlPrefix={BreadcrumbUrlPrefixEnum.Gid}
          />
          <h1 itemProp="headline" className="gid__headline">
            {header || ""}
          </h1>
          <div
            className="seo-typography"
            dangerouslySetInnerHTML={{
              __html: cleanupFromDangerousTags(textTop || ""),
            }}
          />
          <Constructor zone={commonTextTopBottomZone} expertsInitial={expertsInitial} />
          <div
            className="seo-typography"
            dangerouslySetInnerHTML={{
              __html: cleanupFromDangerousTags(textMiddle || ""),
            }}
          />
          <Constructor zone={commonTextMiddleBottomZone} expertsInitial={expertsInitial} />
          <LinkBlock
            links={[
              ...getLinkBlockObject(gidLinks, LinkUrlPrefixEnum.Catalog),
              ...getLinkBlockObject(catalogLinks, LinkUrlPrefixEnum.Catalog),
              ...getLinkBlockObject(categoryLinks, LinkUrlPrefixEnum.Gid),
              ...getLinkBlockObject(articleLinks, LinkUrlPrefixEnum.Article),
            ]}
          />
          <div
            className="seo-typography"
            dangerouslySetInnerHTML={{
              __html: cleanupFromDangerousTags(textBottom || ""),
            }}
          />
          <Constructor zone={zone} expertsInitial={expertsInitial} />
          <ExpertsBlock header="Эксперты Lunaro" experts={experts} showCatalogButton />
          <Constructor zone={commonBottomZone} expertsInitial={expertsInitial} />
          {!!autolinkedGidLinks?.length && (
          <>
            <h2 className="gid__similar">Читайте ещё</h2>
            {shouldLinkBeBullet(autolinkedGidLinks?.length ?? 0) ? (
              <BulletLinkBlock
                links={autolinkedGidLinks}
                urlPrefix={LinkUrlPrefixEnum.Gid}
              />
            ) : (
              <LabelLinkBlock
                links={autolinkedGidLinks}
                urlPrefix={LinkUrlPrefixEnum.Gid}
              />
            )}
          </>
          )}
        </div>
      </div>
    </>
  );
};

export default Gid;
